import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import logo from '../assets/images/advance-logo.png';
import Layout from '../components/Layout';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Certificate = () => {
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const [width, setWidth] = useState(200);
  const [certificate, setCertificate] = useState();
  const [hideDownload, setHideDownload] = useState(false);

  useEffect(() => {
    (async () => {
      let params = new URLSearchParams(window.location.search);
      const certId = params.get('id');
      const hideDownload = params.get('download');
      if (hideDownload) setHideDownload(true);
      const certificateData = await axios.get(`${API_URL}/certificate/${certId}`);
      console.log(certificateData.data);
      setCertificate(certificateData.data);
    })();
  }, []);

  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) return null;
  if (!certificate) return null;

  const _renderAdditionalInfo = () => {
    const additionalInfo = certificate?.additional_info ? certificate?.additional_info : null;
    if (certificate.course_code == 'WIENG5') return null;
    if (!additionalInfo) return <p style={{ color: 'black', marginBottom: 10 }}>Provider #0009318</p>;

    return Object.keys(additionalInfo).map((key) => (
      <p
        style={{
          color: 'black',
          marginBottom: 1,
        }}
      >{`${key}: ${additionalInfo[key]}`}</p>
    ));
  };

  let certType = '';
  if (['FLCOS10', 'FLNAIL10', 'FLFULL10', 'FLFACE10', 'FLCOS4'].indexOf(certificate.course_code) > -1) {
    certType = 'COS';
  } else if (certificate.course_code.includes('ENG')) {
    certType = 'ENG';
  }

  let creditHours = `PDH: ${certificate.credit_hours}hrs`;
  if (certType == 'COS') {
    creditHours = `CE Hours: ${certificate.credit_hours}`;
  }

  const certTitles = certificate.test_titles.split('::').map((t) => (
    <p
      style={{
        color: 'black',
        marginBottom: 1,
        fontWeight: 'bold',
      }}
    >
      {t}
    </p>
  ));

  return (
    <Layout hideButtons={true} hideFooter={true}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'white',
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 10,
          border: '10px solid gray',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            marginTop: 50,
          }}
        >
          <img
            src={logo}
            width="700"
            style={{
              maxWidth: '60%',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h2
            style={{
              color: '#ff6400',
              fontFamily: 'cursive',
              fontSize: 35,
              marginBottom: 5,
            }}
          >
            Certificate of Completion
          </h2>
          <h1 style={{ color: 'black', marginBottom: 5 }}>{certificate.full_name}</h1>
          <h4 style={{ color: 'black', marginBottom: 5 }}>{certificate.license_number}</h4>
          <p style={{ color: 'black', marginBottom: 5 }}>
            <i>For completing the material titled:</i>
          </p>
          {certTitles}

          {_renderAdditionalInfo()}
        </div>
        <div style={{ position: 'absolute', bottom: 0, left: 10, padding: 10 }}>
          <p style={{ color: 'black', marginBottom: 0 }}>{creditHours}</p>
          {certType == 'ENG' && <p style={{ color: 'black', marginBottom: 0 }}>Instructor: Bart Ciambella</p>}
          <p style={{ color: 'black', marginBottom: 0 }}>
            Completion Date: {new Date(certificate.created_at.split('T')[0]).toLocaleDateString('en-US', { timeZone: 'UTC' })}
          </p>
        </div>
        <div style={{ position: 'absolute', bottom: 0, right: 10, padding: 10 }}>
          <p style={{ color: 'black', marginBottom: 0, textAlign: 'right' }}>Josh Ciambella</p>
          <p style={{ color: 'black', marginBottom: 0, textAlign: 'right' }}>CEO</p>
          <p style={{ color: 'black', marginBottom: 0, textAlign: 'right' }}>Email: support@advancece.org</p>
        </div>
      </div>
    </Layout>
  );
};

export default Certificate;
